import { h } from 'vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
export const accountsColumns: ColumnProps[] = [
    {
      title: 'ชื่อผู้ใช้งาน',
      dataIndex: 'accounts.account_informations.account_information_firstname_en',
      sorter: true,
      width: '20%',
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      customRender: ({text: fEn, record}) => {
        const { account_informations } = record.accounts
        const { account_information_firstname_th: fTh, account_information_lastname_en: lEn, account_information_lastname_th: lTh } = account_informations
        return `${(fEn || fTh) ?? '-'} ${fEn ? lEn ?? '-': lTh ?? ''}`
      }
    },
    {
      title: 'Email',
      dataIndex: 'accounts.account_email',
      customRender: ({text}) => text ? h('a', { href: `mailto:${text}`, target: '_blank' }, text): '-'
    },
    {
        title: 'สิทธิการใช้งาน',
        dataIndex: 'role_permission.permissions.permission_name_en',
    },
    {
        title: 'เข้าสู่ระบบ',
        dataIndex: 'role_loggedin',
        align: 'center',
        slots: { customRender: 'login' }
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'created_at',
        slots: { customRender: 'date' }
    },
    {
        title: '',
        dataIndex: 'accounts.account_id',
        slots: { customRender: 'action' },
        align: 'center',
        fixed: 'right'
    }
];

export const permissionsColumns = [
    {
        title: 'รหัสสิทธิ์การใช้งาน',
        dataIndex: 'permission_code',
        // sorter: true,
        // width: '20%',
        // slots: { customRender: 'name' },
    },
    {
        title: 'สิทธิการใช้งาน',
        dataIndex: 'permission_name_en',
        /* filters: [
            { text: 'Male', value: 'male' },
            { text: 'Female', value: 'female' },
        ], */
        // width: '20%',
    },
    /* {
        title: 'เข้าสู่ระบบ',
        dataIndex: 'login',
        slots: { customRender: 'login' }
    }, */
    {
        title: 'วันที่สร้าง',
        dataIndex: 'created_at',
        slots: { customRender: 'date' }
    },
    {
        title: 'สถานะการใช้งาน',
        dataIndex: 'active',
        align: 'center',
        slots: { customRender: 'status' }
    },
    {
        title: '',
        dataIndex: 'id',
        slots: { customRender: 'action' },
        align: 'center'
    }
]